/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="page-banner-area">
        <div className="container">
          <div className="page-banner-content" data-cues="slideInUp">
            <h2>404 Error Page</h2>
            <ul className="list">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>404 ERROR PAGE</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- End Page Banner Area -->
        
        <!-- Start Not Found Area --> */}
      <div className="not-found-area ptb-150">
        <div className="container" data-cues="slideInUp">
          <div className="not-found-content text-center">
            <img src="assets/images/error.png" alt="error-image" />
            <h3>Oops! That page can't be found</h3>
            <Link to="/" className="default-btn">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
