import React from "react";
import { Helmet } from "react-helmet";

const VideoWatchPage = () => {
  return (
    <div>
      <Helmet>
        <title>Dental Implant Procedure Explained</title>
        <meta
          name="description"
          content="Learn about the dental implant procedure at Alps Dental Care, Sector 93, Gurgaon."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            name: "Dental Implant Procedure Explained",
            description:
              "Learn about the dental implant procedure at Alps Dental Care, Sector 93, Gurgaon.",
            thumbnailUrl:
              "https://alpsdentalcare.in/assets/images/services-details.jpg",
            uploadDate: "2024-11-07",
            contentUrl: "https://alpsdentalcare.in/assets/videos/video1.mp4",
            embedUrl: "https://alpsdentalcare.in/videos/video2.mp4",
            duration: "PT2M30S",
            publisher: {
              "@type": "Organization",
              name: "Alps Dental Care",
              logo: {
                "@type": "ImageObject",
                url: "https://alpsdentalcare.in/assets/images/logo.png",
              },
            },
          })}
        </script>
      </Helmet>
      <h1>Dental Implant Procedure Explained</h1>
      <video controls width="800">
        <source
          src="https://alpsdentalcare.in/assets/videos/video1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <p>
        Learn about the dental implant procedure at Alps Dental Care, Sector 93,
        Gurgaon. This video provides step-by-step details on how dental implants
        are performed and their benefits.
      </p>
    </div>
  );
};

export default VideoWatchPage;
